.custom-content {
    font-family: 'Pretendard';
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.custom-content a {
    color: #1D75CA; /* 원하는 색상 */
    text-decoration: none;
}

.custom-content a:hover {
    text-decoration: underline;
}

.custom-content h1, .custom-content h2, .custom-content h3, .custom-content h4, .custom-content h5, .custom-content h6, .custom-content i,.custom-content p,  .custom-content span, .custom-content ul, .custom-content ol, .custom-content li, .custom-content li{
    font-family: 'Pretendard';
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

